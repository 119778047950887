// @flow
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withGroupClass from '@hoc/withGroupClass';

import ParticipantsLayout from '@layout/components/ParticipantsLayout';

import Toast from '_common/services/Toast/Toast';
import ParticipantIndicator from '@shared/ParticipantIndicator';
import PaymentForm from '@shared/Payment/components/PaymentForm';
import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';
import IconChevron from '@icons/components/IconChevron';

import ObjectHelpers from '@helpers/ObjectHelpers';
import DateService from '_common/services/DateService';
import BeneficiaryService from '@beneficiary/services/BeneficiaryService';
import ParticipantService from '@participant/services/ParticipantService';
import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';
import { createRegisteredFromParticipant } from '@participant/helpers/ParticipantsUtils';
import { createRegisteredFromBeneficiary } from '@beneficiary/helpers/BeneficiariesUtils';

import type { Beneficiary } from '@beneficiary/types';
import type { Participant } from '@participant/types';
import type { GroupClassDetailed, UserRegistered } from '@groupClass/types';
import type { Error } from '@core/types';

import { PERSON_TYPE_BENEFICIARY, PERSON_TYPE_PARTICIPANT } from '@person/constants';
import { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';
import {
  CONDITION_TYPE_SUBSIDY,
  CONDITION_TYPE_OTHER,
} from '@paymentConditionType/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS_ADD_PERSON_BY_ID_PERSON } = WEB_PATHS;

const {
  PAGE_NOT_FOUND,
  PARTICIPANTS,
} = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

type UseParamProps = {
  personId: string,
  personType: string,
  classId: string,
};

const FromSearchCreatePayment = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const { personId, personType, classId } = useParams<UseParamProps>();
  const navigate = useNavigate();

  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ beneficiary, setBeneficiary ] = useState<Beneficiary | null>(null);
  const [ participant, setParticipant ] = useState<Participant | null>(null);

  const [ groupClassRegistered, setGroupClassRegistered ] = useState<UserRegistered | null>(null);
  const [ paymentErrors, setPaymentErrors ] = useState<Error[]>(RegisteredsService.errors);
  const [ isLoadingRegistereds, setIsLoadingRegistereds ] = useState<boolean>(RegisteredsService.isLoadingRegistereds);
  const [ isLoadingPersonRegistered, setIsLoadingPersonRegistered ] = useState<boolean>(false);

  const handleUpdateRegisteredState = (data: RegisteredsServiceData): void => {
    setIsLoadingRegistereds(data.isLoadingRegistereds);
    setPaymentErrors(data.errors);
  };

  useEffect(() => RegisteredsService.onChange(handleUpdateRegisteredState), []);

  useEffect(() => {
    if (personType === PERSON_TYPE_BENEFICIARY) {
      BeneficiaryService.fetchOne(parseInt(personId, 10), language)
        .then(setBeneficiary)
        .catch()
        .finally(() => setIsLoading(false));
    } else if (personType === PERSON_TYPE_PARTICIPANT) {
      ParticipantService.fetchOne(parseInt(personId, 10), language)
        .then(setParticipant)
        .catch()
        .finally(() => setIsLoading(false));
    } else {
      navigate(PAGE_NOT_FOUND);
    }
  }, [personType, personId, language, setIsLoading]);

  const hasBeenUnsubscribed = useMemo((): boolean => {
    if (groupClassRegistered) {
      return groupClassRegistered.id !== null;
    }
    return false;
  }, [groupClassRegistered]);

  useEffect(() => {
    if (beneficiary && !hasBeenUnsubscribed && groupClass) {
      setGroupClassRegistered(createRegisteredFromBeneficiary(beneficiary, groupClass));
    }
    if (participant && !hasBeenUnsubscribed && groupClass) {
      setGroupClassRegistered(createRegisteredFromParticipant(participant, groupClass));
    }
  }, [beneficiary, participant, hasBeenUnsubscribed, groupClass, setGroupClassRegistered]);

  useEffect(() => {
    /**
     * At mounting phase
     * Load all the Unregistered participant/beneficiary of the groupClass
     * If the person correspond to an unsubscribed registered, we fetch his informations
     */
    const params = {
      'exists[unsubscribedAt]': true,
    };

    if (groupClass) {
      RegisteredsService.fetchGroupClassUnregistereds(groupClass.id, language, params)
        .then((registereds) => {
          const alreadyRegistered = registereds.find((registered) => registered.person.id === parseInt(personId, 10));
          if (alreadyRegistered) {
            return RegisteredsService.fetchOne(alreadyRegistered.id, language);
          }
          return Promise.reject();
        })
        .then((newRegistered) => setGroupClassRegistered({ ...newRegistered, unsubscribedAt: null }));
    }
  }, [groupClass, language, setGroupClassRegistered]);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const newGroupClassRegistered = ObjectHelpers.deepClone(groupClassRegistered);
    newGroupClassRegistered[e.currentTarget.name] = e.currentTarget.value;
    setGroupClassRegistered(newGroupClassRegistered);
  }, [groupClassRegistered, setGroupClassRegistered]);

  const createPersonRegistered = useCallback((newGroupClassRegistered: UserRegistered): void => {
    setIsLoadingPersonRegistered(true);
    const customError: Error[] = [];

    const commentRequired = newGroupClassRegistered.paymentConditions?.some((paymentCondition) => (
      paymentCondition.code === CONDITION_TYPE_SUBSIDY || paymentCondition.code === CONDITION_TYPE_OTHER
    )) || false;
    if (commentRequired && !newGroupClassRegistered.paymentComment) {
      const error = { propertyPath: 'paymentComment', code: ERROR_IS_BLANK, message: '' };
      customError.push(error);
    }

    if (newGroupClassRegistered.paymentSchedules && newGroupClassRegistered.paymentSchedules.length > 0) {
      newGroupClassRegistered.paymentSchedules.forEach((paymentSchedule, index) => {
        if (paymentSchedule.costAdjust === undefined) {
          const error = { propertyPath: `paymentSchedules[${ index }].costAdjust`, code: ERROR_IS_BLANK, message: '' };
          customError.push(error);
        }
      });
    }

    if (customError.length === 0 && groupClass) {
      RegisteredsService.createRegistered(newGroupClassRegistered, groupClass.id, language)
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellAdded')))
        .then(() => navigate(PARTICIPANTS.replace(':classId', classId)))
        .catch(() => {
          Toast.error(t('groupClasses.groupClass.detailed.errorAllPeriodValidated'));
        });
    }
    setIsLoadingPersonRegistered(false);
    setPaymentErrors(customError);
  }, [groupClass, t, language, classId, setPaymentErrors]);

  const updatePersonRegistered = useCallback((newGroupClassRegistered: UserRegistered): void => {
    setIsLoadingPersonRegistered(true);
    const customError: Error[] = [];

    const commentRequired = newGroupClassRegistered.paymentConditions?.some((paymentCondition) => (
      paymentCondition.code === CONDITION_TYPE_SUBSIDY || paymentCondition.code === CONDITION_TYPE_OTHER
    )) || false;
    if (commentRequired && !newGroupClassRegistered.paymentComment) {
      const error = { propertyPath: 'paymentComment', code: ERROR_IS_BLANK, message: '' };
      customError.push(error);
    }

    if (newGroupClassRegistered.paymentSchedules && newGroupClassRegistered.paymentSchedules.length > 0) {
      newGroupClassRegistered.paymentSchedules.forEach((paymentSchedule, index) => {
        if (paymentSchedule.costAdjust === undefined) {
          const error = { propertyPath: `paymentSchedules[${ index }].costAdjust`, code: ERROR_IS_BLANK, message: '' };
          customError.push(error);
        }
      });
    }

    if (customError.length === 0) {
      RegisteredsService.updateRegistered(newGroupClassRegistered, language)
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellAdded')))
        .then(() => navigate(PARTICIPANTS.replace(':classId', classId)))
        .catch(() => {
          Toast.error(t('groupClasses.groupClass.detailed.errorAllPeriodValidated'));
        })
        .finally(() => setIsLoadingPersonRegistered(false));
    }
    setPaymentErrors(customError);
  }, [t, language, classId, setPaymentErrors]);

  const acceptSubscription = useCallback((): void => {
    if (groupClassRegistered) {
      !hasBeenUnsubscribed
        ? createPersonRegistered(groupClassRegistered)
        : updatePersonRegistered(groupClassRegistered);
    }
  }, [groupClassRegistered, hasBeenUnsubscribed, createPersonRegistered, updatePersonRegistered]);

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="button-edit-mode">
      <Button
        className="button-position"
        isLoading={ isLoadingPersonRegistered }
        onClick={ acceptSubscription }
        type={ buttonConstants.PRIMARY }
      >
        { t('groupClasses.groupClass.peoples.form.addToGroupClass') }
      </Button>
    </div>
  ), [acceptSubscription, language, isLoadingPersonRegistered]);

  const groupClassActivityName = useMemo((): string => (
    groupClass?.activity?.name || ''
  ), [groupClass]);

  const groupClassCity = useMemo((): string => (
    groupClass?.address.city || ''
  ), [groupClass]);

  const groupClassDayTime = useMemo((): string => {
    if (groupClass) {
      const { day, startTime, endTime } = groupClass;
      return DateService.localDateAndTimeDisplay(day, startTime, endTime, language);
    }
    return '';
  }, [groupClass, language]);

  const firstName = useMemo((): string => {
    if (beneficiary) {
      return beneficiary.firstName;
    } else if (participant) {
      return participant.firstName;
    }
    return '';
  }, [beneficiary, participant]);

  const lastNameUpperCased = useMemo((): string => {
    if (beneficiary) {
      return beneficiary.lastName.toUpperCase();
    } else if (participant) {
      return participant.lastName.toUpperCase();
    }
    return '';
  }, [beneficiary, participant]);

  const groupClassPostCode = groupClass?.address.postalCode || '';

  const isBeneficiary = personType === PERSON_TYPE_BENEFICIARY;

  const goBack = useCallback((): void => {
    navigate(PARTICIPANTS_ADD_PERSON_BY_ID_PERSON
      .replace(':classId', String(groupClass?.id))
      .replace(':personId', String(personId))
      .replace(':personType', personType));
  }, [groupClass, personId, personType]);

  return (
    <ParticipantsLayout groupClass={ groupClass }>
      <>
        <Button
          onClick={ goBack }
          type={ buttonConstants.TERTIARY }
          className="mb-5"
        >
          <IconChevron className="back-icon" /> { t('common.back') }
        </Button>
        <FormContainer
          title={
            <>
              <ParticipantIndicator isBeneficiary={ isBeneficiary } extraCss="participant-tab" />
              { !isLoading && (firstName + ' ' + lastNameUpperCased) }
            </>
          }
          className="beneficiary-request-card"
          isLoading={ isLoadingRegistereds }
          footerButtonActions={ footerButtons }
        >
          <div className="block-infos">
            <div className="block-title">
              <div className="flex wrap">
                <h2 className="title-two">
                  { `${ t('groupClasses.groupClass.peoples.form.paiementBlocTitle') } ${ groupClassActivityName } | ` }
                </h2>
                <h2 className="uppercase-first title-two">{ `${ groupClassPostCode } ${ groupClassCity }` }</h2>
                <h2 className="title-two">{ groupClassDayTime }</h2>
              </div>
            </div>
            <div className="separator" />
            <div className="content is-open" >
              { groupClass && (
                <PaymentForm
                  data={ groupClassRegistered }
                  onChange={ handleChange }
                  onChangeData={ setGroupClassRegistered }
                  groupClass={ groupClass }
                  errors={ paymentErrors }
                />
              ) }
            </div>
          </div>
        </FormContainer>
      </>
    </ParticipantsLayout>
  );
};

export {
  FromSearchCreatePayment,
};

export default withGroupClass(FromSearchCreatePayment);
