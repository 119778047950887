// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';

import type { Beneficiary } from '@beneficiary/types';
import type { GroupClassRequest, ShortGroupClassRequest } from '@participant/types';
import type { Error } from '@core/types';

import ParticipantIndicator from '@shared/ParticipantIndicator';
import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';
import Modal from '@shared/Modal/Modal';
import Toast from '_common/services/Toast/Toast';

import PersonalForm from '@shared/Personal/components/PersonalForm';

import RequestsService, { type RequestsServiceData } from '@beneficiary/services/RequestsService';
import BeneficiaryService, { type BeneficiaryServiceData } from '@beneficiary/services/BeneficiaryService';

import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS, PARTICIPANTS_CLASS_REQUEST_PAYMENT, PARTICIPANTS_CLASS_REQUEST_PERSON } = WEB_PATHS;

type Props = {
  request: GroupClassRequest,
};

type UseParamProps = {
  classId: string,
};

const BeneficiaryRequestEditPerson = (props: Props): React$Node => {
  const { request } = props;
  const { classId } = useParams<UseParamProps>();
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ isLoading, setLoading ] = useState<boolean>(BeneficiaryService.isLoadingValue);
  const [ beneficiary, setBeneficiary ] = useState<Beneficiary | null>(BeneficiaryService.beneficiaryValue);
  const [ errors, setErrors ] = useState<Error[]>(BeneficiaryService.errorsValues);
  const [ isActiveModal, setIsActiveModal ] = useState<boolean>(false);
  const [ authorizeData, setAuthorizeData ] = useState<boolean>(true);
  const [ requests, setRequests ] = useState<ShortGroupClassRequest[]>(RequestsService.groupClassRequests);
  const [ isLoadingUpdateBeneficiary, setIsLoadingUpdateBeneficiary ] = useState<boolean>(false);

  const {
    firstName = '',
    lastName = '',
  } = beneficiary || {};

  const toggleAuthorizeData = useCallback((): void => {
    setAuthorizeData(!authorizeData);
  }, [authorizeData]);

  const lastNameUpperCased = useMemo((): string => (
    lastName.toUpperCase()
  ), [lastName]);

  const toggleModal = useCallback((): void => {
    setIsActiveModal(!isActiveModal);
  }, [isActiveModal]);

  const handleUpdateState = (data: BeneficiaryServiceData): void => {
    const { isLoading, errors, beneficiary } = data;
    setLoading(isLoading);
    if (!isLoading && errors.length === 0) setBeneficiary(beneficiary);
    setErrors(errors);
  };

  useEffect(() => BeneficiaryService.onChange(handleUpdateState), []);

  useEffect(() => {
    BeneficiaryService.fetchOne(request.person.id, language);
  }, [request, language]);

  const handleRequestsUpdateState = (data: RequestsServiceData): void => {
    setRequests(data.groupClassRequests);
  };

  useEffect(() => RequestsService.onChange(handleRequestsUpdateState), []);

  const handleNext = useCallback((): void => {
    if (beneficiary) {
      setIsLoadingUpdateBeneficiary(true);
      BeneficiaryService.update(beneficiary.id, beneficiary)
        .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated')))
        .then(() => navigate(PARTICIPANTS_CLASS_REQUEST_PAYMENT.replace(':classId', classId).replace(':requestId', String(request.id))));
      setIsLoadingUpdateBeneficiary(false);
    }
  }, [beneficiary, classId, request, navigate]);

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="buttons-footer">
      <Button
        onClick={ toggleModal }
        type={ buttonConstants.TERTIARY }
        isOutlined
      >
        { t('groupClasses.groupClass.peoples.form.rejectSubscription') }
      </Button>
      <Button
        onClick={ handleNext }
        isLoading={ isLoadingUpdateBeneficiary }
        type={ buttonConstants.PRIMARY }
      >
        { t('groupClasses.groupClass.peoples.form.next') }
      </Button>
    </div>
  ), [toggleModal, handleNext, language, t, isLoadingUpdateBeneficiary]);

  const redirectNext = useCallback((requestId: number, classId: string): string => {
    if (requests.length > 1) {
      const currentIdx = requests.findIndex((listRequest) => listRequest.id === requestId);
      const nextIdx = currentIdx + 1 === requests.length ? 0 : currentIdx + 1;
      const nextRequest = requests[nextIdx];
      return PARTICIPANTS_CLASS_REQUEST_PERSON.replace(':classId', classId).replace(':requestId', String(nextRequest.id));
    }

    return PARTICIPANTS.replace(':classId', classId);
  }, [requests]);

  const handleDeleteRequest = useCallback((): void => {
    RequestsService.declineRequest(request.id)
      .then(() => toggleModal())
      .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated')))
      .then(() => navigate(redirectNext(request.id, classId)));
  }, [request, classId, language, toggleModal, redirectNext]);

  const footerModal: React$Node = useMemo((): React$Node => (
    <footer className="modal-footer">
      <Button
        type={ buttonConstants.TERTIARY }
        onClick={ toggleModal }
        isOutlined
      >
        { t('common.cancel') }
      </Button>

      <Button
        type={ buttonConstants.SECONDARY }
        className="button is-success confirm"
        onClick={ handleDeleteRequest }
        isLoading={ isLoadingUpdateBeneficiary }
      >
        { t('groupClasses.groupClass.peoples.form.popUpConfirm') }
      </Button>
    </footer>
  ), [toggleModal, handleDeleteRequest, language, t, isLoadingUpdateBeneficiary]);

  return (
    <>
      <FormContainer
        title={
          <>
            <ParticipantIndicator isBeneficiary extraCss="participant-tab" />
            { !isLoading && (firstName + ' ' + lastNameUpperCased) }
          </>
        }
        className="beneficiary-request-card"
        footerButtonActions={ footerButtons }
      >
        <div className="block-infos">
          <div className="block-title">
            <h2 className="title-two">
              { t('groupClasses.groupClass.peoples.form.personalInfosTitle') }
            </h2>
          </div>
          <div
            className="separator"
          />
          <div
            className= "content is-open"
          >
            <PersonalForm
              user={ beneficiary }
              setUser={ setBeneficiary }
              errors={ errors }
              authorizeData={ authorizeData }
              toggleAuthorizeData={ toggleAuthorizeData }
            />
          </div>
        </div>
      </FormContainer>
      <Modal
        isActive={ isActiveModal }
        isClipped
        onClose={ toggleModal }
        title={ t('groupClasses.groupClass.detailed.cancelPreregisterModalAdmin') }
        footer={ footerModal }
      >
        { t('groupClasses.groupClass.peoples.form.popUpSentenceAdmin', { firstName, lastName: lastNameUpperCased }) }
      </Modal>
    </>
  );
};

export default BeneficiaryRequestEditPerson;
