// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import withGroupClass from '@hoc/withGroupClass';

import ParticipantsLayout from '@layout/components/ParticipantsLayout';

import PersonalForm from '@shared/Personal/components/PersonalForm';
import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';
import ParticipantIndicator from '@shared/ParticipantIndicator';
import Toast from '_common/services/Toast/Toast';
import IconChevron from '@icons/components/IconChevron';

import BeneficiaryService, { type BeneficiaryServiceData } from '@beneficiary/services/BeneficiaryService';
import ParticipantService, { type ParticipantServiceData } from '@participant/services/ParticipantService';

import type { Beneficiary } from '@beneficiary/types';
import type { Participant } from '@participant/types';
import type { GroupClassDetailed } from '@groupClass/types';
import type { Error } from '@core/types';

import { PERSON_TYPE_BENEFICIARY, PERSON_TYPE_PARTICIPANT } from '@person/constants';
import { WEB_PATHS } from '@app/constants/paths';
import { validateParticipantForm } from '@participant/helpers/ParticipantsUtils';
const {
  PARTICIPANTS_SEARCH,
  PARTICIPANTS_ADD_PERSON_BY_ID_PAYMENT,
  PAGE_NOT_FOUND,
} = WEB_PATHS;

type Props = {
  groupClass: GroupClassDetailed | null,
};

export const FromSearchEditPerson = (props: Props): React$Node => {
  const { groupClass } = props;
  const { t, i18n: { language } } = useTranslation();
  const { personId, personType } = useParams();
  const navigate = useNavigate();

  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ beneficiary, setBeneficiary ] = useState<Beneficiary | null>(null);
  const [ participant, setParticipant ] = useState<Participant | null>(null);
  const [ errors, setErrors ] = useState<Error[]>([]);
  const [ authorizeData, setAuthorizeData ] = useState<boolean>(true);
  const [ isLoadingUpdateBeneficiary, setIsLoadingUpdateBeneficiary ] = useState<boolean>(false);

  const firstName = useMemo((): string => {
    if (beneficiary) {
      return beneficiary.firstName;
    } else if (participant) {
      return participant.firstName;
    }
    return '';
  }, [beneficiary, participant]);

  const lastNameUpperCased = useMemo((): string => {
    if (beneficiary) {
      return beneficiary.lastName.toUpperCase();
    } else if (participant) {
      return participant.lastName.toUpperCase();
    }
    return '';
  }, [beneficiary, participant]);

  const handleParticipantChange = (data: ParticipantServiceData): void => {
    if (participant) {
      setErrors(data.errors);
    }
  };

  const handleBeneficiaryChange = (data: BeneficiaryServiceData): void => {
    if (beneficiary) {
      setErrors(data.errors);
    }
  };

  useEffect(() => ParticipantService.onChange(handleParticipantChange), []);
  useEffect(() => BeneficiaryService.onChange(handleBeneficiaryChange), []);

  useEffect(() => {
    if (personType === PERSON_TYPE_BENEFICIARY) {
      BeneficiaryService.fetchOne(personId, language)
        .then(setBeneficiary)
        .catch((err) => setErrors(err))
        .finally(() => setIsLoading(false));
    } else if (personType === PERSON_TYPE_PARTICIPANT) {
      ParticipantService.fetchOne(personId, language)
        .then(setParticipant)
        .catch((err) => setErrors(err))
        .finally(() => setIsLoading(false));
    } else {
      navigate(PAGE_NOT_FOUND);
    }
  }, [personType, personId, language]);

  const validateAndSubmit = useCallback((): void => {
    setIsLoadingUpdateBeneficiary(true);
    if (personType === PERSON_TYPE_BENEFICIARY) {
      beneficiary && BeneficiaryService.update(personId, beneficiary)
        .then(() => {
          Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated'));
          navigate(PARTICIPANTS_ADD_PERSON_BY_ID_PAYMENT
            .replace(':classId', String(groupClass?.id))
            .replace(':personId', personId)
            .replace(':personType', personType));
        })
        .catch(() => {
          Toast.error(t('groupClasses.groupClass.peoples.form.genericError'));
        });
    } else if (personType === PERSON_TYPE_PARTICIPANT) {
      const formErrors = validateParticipantForm(participant);
      setErrors(formErrors);

      if (formErrors.length > 0) {
        setIsLoadingUpdateBeneficiary(false);
        return;
      }

      participant && ParticipantService.update(personId, participant)
        .then(() => {
          Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated'));
          navigate(PARTICIPANTS_ADD_PERSON_BY_ID_PAYMENT
            .replace(':classId', String(groupClass?.id))
            .replace(':personId', personId)
            .replace(':personType', personType));
        })
        .catch(() => {
          Toast.error(t('groupClasses.groupClass.peoples.form.genericError'));
        });
    } else {
      Toast.warning(t('groupClasses.groupClass.peoples.form.personCreated'));
    }
    setIsLoadingUpdateBeneficiary(false);
  }, [personType, beneficiary, participant, personId, groupClass, t]);

  const isBeneficiary = personType === PERSON_TYPE_BENEFICIARY;

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="button-edit-mode">
      <Button
        className="button-position"
        onClick={ validateAndSubmit }
        type={ buttonConstants.PRIMARY }
        isLoading={ isLoadingUpdateBeneficiary }
      >
        { t('groupClasses.groupClass.peoples.form.next') }
      </Button>
    </div>
  ), [validateAndSubmit, language, isLoadingUpdateBeneficiary]);

  const toggleAuthorizeData = useCallback((): void => {
    setAuthorizeData(!authorizeData);
  }, [authorizeData]);

  return (
    <ParticipantsLayout groupClass={ groupClass }>
      <>
        <Link
          to={ groupClass ? PARTICIPANTS_SEARCH.replace(':classId', String(groupClass.id)) : '' }
          state={ { keepSearch: true } }
          className="button button-tertiary button-position mb-5"
        >
          <IconChevron className="back-icon" />
          { t('groupClasses.groupClass.peoples.form.cancelAddToGroupClass') }
        </Link>
        <FormContainer
          title={
            <>
              <ParticipantIndicator isBeneficiary={ isBeneficiary } extraCss="participant-tab" />
              { !isLoading && (firstName + ' ' + lastNameUpperCased) }
            </>
          }
          className="beneficiary-request-card"
          isLoading={ (isLoading) }
          footerButtonActions={ footerButtons }
        >
          <div className="block-infos">
            <div className="block-title">
              <h2 className="title-two">{ t('groupClasses.groupClass.peoples.form.personalInfosTitle') }</h2>
            </div>
            <div className="separator" />
            <div className="content is-open">
              { beneficiary && (
                <PersonalForm
                  user={ beneficiary }
                  setUser={ setBeneficiary }
                  errors={ errors }
                  disableEmail
                  authorizeData={ authorizeData }
                  toggleAuthorizeData={ toggleAuthorizeData }
                />
              ) }
              { participant && (
                <PersonalForm
                  user={ participant }
                  setUser={ setParticipant }
                  errors={ errors }
                  authorizeData={ authorizeData }
                  toggleAuthorizeData={ toggleAuthorizeData }
                />
              ) }
            </div>
          </div>
        </FormContainer>
      </>
    </ParticipantsLayout>
  );
};

export default withGroupClass(FromSearchEditPerson);
