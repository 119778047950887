// @flow
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { enGB, es, fr } from 'date-fns/locale';
import clsx from 'clsx';
import DatePicker, { registerLocale } from 'react-datepicker';

import type { Colors, Sizes, LabelProps, HelpProps } from '@core/types';
import type { IconsProps } from '_common/components/control/Control';

import Help from '_common/components/help/Help';
import Field from '_common/components/field/Field';
import Label from '_common/components/label/Label';

registerLocale('es', es);
registerLocale('en', enGB);
registerLocale('fr', fr);

type Props = {
  className?: string,
  name: string,
  label?: React$Node,
  color?: Colors,
  size?: Sizes,
  labelProps?: LabelProps,
  inputProps?: LabelProps,
  helpProps?: HelpProps,
  icons?: IconsProps,
  controlIsExpanded?: boolean,
  type?: string,
  required?: boolean,
  isDisabled?: boolean,
  step?: number,
  placeholder?: string,
  value: Date | null,
  maxDate?: Date | null,
  minDate?: Date | null,
  yearDropdownItemNumber?: number,
  showYearDropdown?: boolean,
  onChange?: (date: Date) => void,
  id?: string,
  startDate?: Date | null,
  endDate?: Date | null,
};

const DatePickerField = (props: Props): React$Node => {
  const {
    className,
    name,
    label,
    color,
    size,
    labelProps = {},
    inputProps,
    helpProps,
    isDisabled,
    required,
    type,
    step,
    placeholder,
    value,
    maxDate,
    minDate,
    yearDropdownItemNumber,
    showYearDropdown,
    onChange,
    id,
  } = props;

  const { className: labelClassName, ...othersLabelProps } = labelProps;

  const { t, i18n: { language } } = useTranslation();
  const format = t('dates.dateFormat');

  const datePickerFieldClassName = useMemo ((): string => clsx({
    'date-picker-field': true,
    [String(className)]: !!className,
  }), [className]);

  const datePickerFieldLabelClassName = useMemo ((): string => clsx({
    'g-label': true,
    'is-flex': true,
    [String(labelClassName)]: !!labelClassName,
  }), [labelClassName]);

  const datePickerClassName = useMemo((): string => clsx({
    'input g-form-field': true,
    'border-error': !!color,
  }), [color]);

  return (
    <Field className={ datePickerFieldClassName }>
      { label && (
        <Label
          { ...othersLabelProps }
          size={ size }
          htmlFor={ name }
          id={ id }
          className={ datePickerFieldLabelClassName }
        >
          { label } { required && '*' }
        </Label>
      ) }
      <DatePicker
        { ...inputProps }
        dateFormat={ format }
        value={ value }
        inputSize={ size }
        name={ name }
        step={ step }
        borderColor={ color }
        type={ type ? type : 'text' }
        required={ required }
        className={ datePickerClassName }
        onChange={ onChange }
        disabled={ isDisabled }
        placeholderText={ placeholder }
        locale={ language }
        maxDate={ maxDate }
        minDate={ minDate }
        selected={ value }
        yearDropdownItemNumber={ yearDropdownItemNumber }
        showYearDropdown={ showYearDropdown }
        scrollableYearDropdown
        showMonthDropdown
        useShortMonthInDropdown
      />
      <div className="helper">
        { helpProps && <Help { ...helpProps } textColor={ color } /> }
      </div>
    </Field>
  );
};

export default DatePickerField;
