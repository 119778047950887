// @flow
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import type { GroupClassRequest, ShortGroupClassRequest } from '@participant/types';
import type { GroupClassDetailed, UserRegistered } from '@groupClass/types';
import type { Error } from '@core/types';

import ObjectHelpers from '@helpers/ObjectHelpers';
import { createUserRegisteredFromRequest } from '@groupClass/helpers/RequestsUtils';

import ParticipantIndicator from '@shared/ParticipantIndicator';
import Button, { buttonConstants } from '@shared/Button';
import FormContainer from '@shared/FormContainer';
import Modal from '@shared/Modal/Modal';

import PaymentForm from '@shared/Payment/components/PaymentForm';

import DateService from '_common/services/DateService';
import Toast from '_common/services/Toast/Toast';

import RegisteredsService, { type RegisteredsServiceData } from '@groupClass/services/RegisteredsService';
import RequestsService, { type RequestsServiceData } from '@beneficiary/services/RequestsService';

import { ERROR_IS_BLANK } from '@helpers/TranslationHelpers';
import {
  CONDITION_TYPE_SUBSIDY,
  CONDITION_TYPE_OTHER,
} from '@paymentConditionType/constants';
import { WEB_PATHS } from '@app/constants/paths';
const { PARTICIPANTS, PARTICIPANTS_CLASS_REQUEST_PERSON } = WEB_PATHS;

type Props = {
  request: GroupClassRequest,
  groupClass: GroupClassDetailed,
};

type UseParamProps = {
  classId: string,
};

const BeneficiaryRequestEditPayment = (props: Props): React$Node => {
  const { request, groupClass } = props;
  const { classId } = useParams<UseParamProps>();
  const { t, i18n: { language } } = useTranslation();
  const navigate = useNavigate();

  const [ groupClassRegistered, setGroupClassRegistered ] = useState<UserRegistered>(createUserRegisteredFromRequest(request));
  const [ paymentErrors, setPaymentErrors ] = useState<Error[]>(RegisteredsService.errors);
  const [ isLoadingRegistereds, setIsLoadingRegistereds ] = useState<boolean>(RegisteredsService.isLoadingRegistereds);
  const [ isActiveModal, setIsActiveModal ] = useState<boolean>(false);
  const [ requests, setRequests ] = useState<ShortGroupClassRequest[]>(RequestsService.groupClassRequests);

  const handleUpdateRegisteredState = (data: RegisteredsServiceData): void => {
    setIsLoadingRegistereds(data.isLoadingRegistereds);
    setPaymentErrors(data.errors);
  };

  useEffect(() => RegisteredsService.onChange(handleUpdateRegisteredState), []);

  useEffect(() => {
    /**
     * At mounting phase
     * Load all the Unregistered participant/beneficiary of the groupClass
     * If the person correspond to an unsubscribed registered, we fetch his informations
     */
    const params = {
      'exists[unsubscribedAt]': true,
    };

    RegisteredsService.fetchGroupClassRegistereds(groupClass.id, language, params)
      .then((registereds) => {
        const alreadyRegistered = registereds.find((registered) => registered.person.id === request.person.id);
        if (alreadyRegistered) {
          return RegisteredsService.fetchOne(alreadyRegistered.id, language);
        }
        return Promise.reject();
      })
      .then((newRegistered) => setGroupClassRegistered({ ...newRegistered, unsubscribedAt: null }))
      .catch(() => {});
  }, [groupClass, language]);

  const handleRequestsUpdateState = (data: RequestsServiceData): void => {
    setRequests(data.groupClassRequests);
  };

  useEffect(() => RequestsService.onChange(handleRequestsUpdateState), []);

  const hasBeenUnsubscribed = useMemo((): boolean => (
    groupClassRegistered ? groupClassRegistered.id !== null : false
  ), [groupClassRegistered]);

  const lastNameUpperCased = useMemo((): string => (
    request.person.lastName.toUpperCase()
  ), [request]);

  const firstName = useMemo((): string => (
    request.person.firstName
  ), [request]);

  const toggleModal = useCallback((): void => {
    setIsActiveModal(!isActiveModal);
  }, [isActiveModal]);

  const handleChange = useCallback((e: SyntheticEvent<HTMLInputElement>): void => {
    const newGroupClassRegistered = ObjectHelpers.deepClone(groupClassRegistered);
    newGroupClassRegistered[e.currentTarget.name] = e.currentTarget.value;
    setGroupClassRegistered(newGroupClassRegistered);
  }, [groupClassRegistered]);

  const groupClassActivityName = request?.groupClass?.activity?.name || '';
  const groupClassCity = request?.groupClass?.address.city || '';
  const groupClassPostCode = request?.groupClass?.address.postalCode || '';

  const groupClassDayTime = useMemo((): string => {
    if (request && request.groupClass) {
      const { day, startTime, endTime } = request.groupClass;
      return DateService.localDateAndTimeDisplay(day, startTime, endTime, language);
    }
    return '';
  }, [request, language]);

  const redirectNext = useCallback((requestId: number, classId: string): string => {
    if (requests.length > 1) {
      const currentIdx = requests.findIndex((listRequest) => listRequest.id === requestId);
      const nextIdx = currentIdx + 1 === requests.length ? 0 : currentIdx + 1;
      const nextRequest = requests[nextIdx];
      return PARTICIPANTS_CLASS_REQUEST_PERSON.replace(':classId', classId).replace(':requestId', String(nextRequest.id));
    }

    return PARTICIPANTS.replace(':classId', classId);
  }, [requests]);

  const handleDeleteRequest = useCallback((): void => {
    RequestsService.declineRequest(request.id)
      .then(toggleModal)
      .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellUpdated')))
      .then(() => navigate(redirectNext(request.id, classId)));
  }, [request, classId, language, toggleModal]);

  const createPersonRegistered = useCallback((newGroupClassRegistered: UserRegistered): Promise<any> => {
    const customError: Error[] = [];

    const commentRequired = newGroupClassRegistered.paymentConditions?.some((paymentCondition) => (
      paymentCondition.code === CONDITION_TYPE_SUBSIDY || paymentCondition.code === CONDITION_TYPE_OTHER
    )) || false;
    if (commentRequired && !newGroupClassRegistered.paymentComment) {
      const error = { propertyPath: 'paymentComment', code: ERROR_IS_BLANK, message: '' };
      customError.push(error);
    }

    if (newGroupClassRegistered.paymentSchedules && newGroupClassRegistered.paymentSchedules.length > 0) {
      newGroupClassRegistered.paymentSchedules.forEach((paymentSchedule, index) => {
        if (paymentSchedule.costAdjust === undefined) {
          const error = { propertyPath: `paymentSchedules[${ index }].costAdjust`, code: ERROR_IS_BLANK, message: '' };
          customError.push(error);
        }
      });
    }

    if (customError.length === 0) {
      return RegisteredsService.createRegistered(newGroupClassRegistered, groupClass.id, language);
    }
    setPaymentErrors(customError);
    return Promise.reject();
  }, [groupClass, language, classId, setPaymentErrors]);

  const updatePersonRegistered = useCallback((newGroupClassRegistered: UserRegistered): Promise<any> => {
    const customError: Error[] = [];
    const commentRequired = newGroupClassRegistered.paymentConditions?.some((paymentCondition) => (
      paymentCondition.code === CONDITION_TYPE_SUBSIDY || paymentCondition.code === CONDITION_TYPE_OTHER
    )) || false;

    if (commentRequired && !newGroupClassRegistered.paymentComment) {
      const error = { propertyPath: 'paymentComment', code: ERROR_IS_BLANK, message: '' };
      customError.push(error);
    }

    if (newGroupClassRegistered.paymentSchedules && newGroupClassRegistered.paymentSchedules.length > 0) {
      newGroupClassRegistered.paymentSchedules.forEach((paymentSchedule, index) => {
        if (paymentSchedule.costAdjust === undefined) {
          const error = { propertyPath: `paymentSchedules[${ index }].costAdjust`, code: ERROR_IS_BLANK, message: '' };
          customError.push(error);
        }
      });
    }

    if (customError.length === 0) {
      return RegisteredsService.updateRegistered(newGroupClassRegistered, language);
    }
    setPaymentErrors(customError);
    return Promise.reject();
  }, [t, language, classId, setPaymentErrors]);

  const acceptSubscription = useCallback((): void => {
    (!hasBeenUnsubscribed
      ? createPersonRegistered(groupClassRegistered)
      : updatePersonRegistered(groupClassRegistered)
    )
      .then(() => Toast.success(t('groupClasses.groupClass.peoples.form.wellAdded')))
      .then(() => navigate(redirectNext(request.id, classId)))
      .catch(() => {
        Toast.error(t('groupClasses.groupClass.detailed.errorAllPeriodValidated'));
      });
  }, [
    hasBeenUnsubscribed,
    createPersonRegistered,
    updatePersonRegistered,
    groupClassRegistered,
    redirectNext,
    request,
  ]);

  const footerModal: React$Node = useMemo((): React$Node => (
    <footer className="modal-footer">
      <Button
        type={ buttonConstants.TERTIARY }
        onClick={ toggleModal }
        isOutlined
      >
        { t('common.cancel') }
      </Button>

      <Button
        type={ buttonConstants.SECONDARY }
        className="button is-success confirm"
        onClick={ handleDeleteRequest }
      >
        { t('groupClasses.groupClass.peoples.form.popUpConfirm') }
      </Button>
    </footer>
  ), [toggleModal, handleDeleteRequest, language, t]);

  const footerButtons: React$Node = useMemo((): React$Node => (
    <div className="buttons-footer">
      <Button
        onClick={ toggleModal }
        type={ buttonConstants.TERTIARY }
        isOutlined
      >
        { t('groupClasses.groupClass.peoples.form.rejectSubscription') }
      </Button>
      <Button
        onClick={ acceptSubscription }
        type={ buttonConstants.PRIMARY }
      >
        { t('groupClasses.groupClass.peoples.form.acceptSubscription') }
      </Button>
    </div>
  ), [toggleModal, acceptSubscription, language, t]);

  return (
    <>
      <FormContainer
        title={
          <>
            <ParticipantIndicator isBeneficiary extraCss="participant-tab" />
            { (firstName + ' ' + lastNameUpperCased) }
          </>
        }
        className="beneficiary-request-card"
        isLoading={ isLoadingRegistereds }
        footerButtonActions={ footerButtons }
      >
        <div className="block-infos">
          <div className="block-title">
            <div className="flex wrap">
              <h2 className="title-two">
                { `${ t('groupClasses.groupClass.peoples.form.paiementBlocTitle') }
                ${ groupClassActivityName } - ` }
              </h2>
              <h2 className="uppercase-first title-two">{ `${ groupClassPostCode } ${ groupClassCity }` }</h2>
              <h2 className="title-two">{ groupClassDayTime }</h2>
            </div>
          </div>
          <div
            className="separator"
          />
          <div
            className="content is-open"
          >
            <PaymentForm
              data={ groupClassRegistered }
              onChange={ handleChange }
              onChangeData={ setGroupClassRegistered }
              groupClass={ groupClass }
              errors={ paymentErrors }
            />
          </div>
        </div>
      </FormContainer>
      <Modal
        isActive={ isActiveModal }
        isClipped
        onClose={ toggleModal }
        title={ t('groupClasses.groupClass.detailed.cancelPreregisterModalAdmin') }
        footer={ footerModal }
      >
        { t('groupClasses.groupClass.peoples.form.popUpSentenceAdmin', { firstName, lastName: lastNameUpperCased }) }
      </Modal>
    </>
  );
};

export default BeneficiaryRequestEditPayment;
